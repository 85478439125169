<template>
  <div class="container-fluid login-page">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-12 col-md-5 col-lg-4 justify-content-center align-items-center">
        <el-card>
          <el-form hide-required-asterisk label-position="top" ref="userForm" :model="User">
            <div class="d-flex justify-content-center">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <img src="../../assets/img/logo.png" width="230">
                <h4 class="fw-bold text-secondary">Welcome Back!</h4>
              </div>
            </div>
            <el-form-item prop="username" :rules="formRules.username">
              <template #label>
                <span class="required">Username</span>
              </template>
              <el-input size="large" v-model="User.username">
                <template #prepend>
                  <el-button tabindex="-1">
                    <i class="bi bi-person"></i>
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password" :rules="formRules.password">
              <template #label>
                <span class="required">Password</span>
              </template>
              <el-input size="large" v-model="User.password" @keyup.enter="Login()" show-password>
                <template #prepend>
                  <el-button tabindex="-1">
                    <i class="bi bi-lock"></i>
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password" :rules="formRules.password">
              <template #label>
                <div class="d-flex align-items-center">

                  <el-checkbox class="me-2" v-model="rememberMe"></el-checkbox>
                  <span class="">Remember me?</span>

                </div>
              </template>

            </el-form-item>
            <el-form-item>
              <router-link to="forgot_password" class="px-0">
                <el-button tabindex="-1" link type="success">Forgot password?</el-button>
              </router-link>
            </el-form-item>
          </el-form>
          <template #footer>
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12 col-md-8 pe-md-2">
                  <el-button :loading="loading" @click="Login" :disabled="!User.username || !User.password" size="large"
                    type="primary" class="w-100">Login
                  </el-button>
                </div>
                <div class="col-12 col-md-4 mt-2 mt-md-0">
                  <router-link to="signup">
                    <el-button size="large" type="primary" class="w-100">Sign Up</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </el-card>
      </div>
    </div>
    <div v-if="loading" class="spinner-border login-loader"></div>
  </div>
</template>
<script>
import authenticationService from "@/Services/userService";
import { ShowMessage } from "@/Utility/Utility";
import axios from 'axios'

export default {
  name: "LoginPage",
  data() {
    return {
      loading: false,
      rememberMe: false,
      User: {
        username: "",
        password: "",
        newPassword:""
      },
      formRules: {
        password: [
          { required: true, message: 'Please enter your password', trigger: 'blur' },
          // You can add more rules for the name field if needed
        ],
        username: [
          { required: true, message: 'Please enter the email', trigger: 'blur' },
          //{type: 'email', message: 'Please enter a valid email', trigger: ['blur', 'change']},
          //{validator: this.validateUsername, trigger: 'blur'}
        ],
      },
    }
  },
  methods: {
    /* validateUsername(rule, value, callback) {
       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
       if (emailRegex.test(value)) {
         callback();
       } else {
         callback(new Error('Please enter a valid email address'));
       }
     },*/

    async Login() {
      this.loading = true;
      try {
        let response = await authenticationService.login(this.User);
        sessionStorage.setItem("token", response.data.token)
        sessionStorage.setItem("userId", response.data.userId);
        axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;
        this.getUser();
        this.loading = false;
      } catch (e) {
        ShowMessage("error", e.message ?? "Username or password incorrect!");
        this.loading = false;
      }
      this.loading = false;
    },
    async getUser() {
      try {
        this.loading = true;
        let response = await authenticationService.getUser();
        this.User = response.data;
        sessionStorage.setItem("LoggedIn", true);
        this.$store.state.LoggedIn = true;
        sessionStorage.setItem("Role", this.User.role);
        this.setCookies();
        this.$router.push("/home");
      } catch (e) {
        this.loading = false;
        console.log(e)
      }
    },
    setCookies() {
      if (this.rememberMe) {
        document.cookie = "rememberme=" + this.rememberMe + ";path=https://app.magiclinkz.com/"
        document.cookie = "username=" + this.User.username + ";path=https://app.magiclinkz.com/"
        document.cookie = "password=" + this.User.password + ";path=https://app.magiclinkz.com/"
        //document.cookie = "rememberme="+this.rememberMe+";path=http://localhost:8080"
        //document.cookie = "username="+this.User.username+";path=http://localhost:8080"
        //document.cookie = "password="+this.User.password+";path=http://localhost:8080"
      }

    },
    getCookies() {
      this.rememberMe = this.getCookieValue('rememberme') === 'true'; // Set rememberMe based on the 'true' value in cookie
     
      if (this.rememberMe) {
        this.User.username = this.getCookieValue('username');
        this.User.password = this.getCookieValue('password');
      }
    },
    getCookieValue(key) {
      const cookieString = document.cookie;
      const cookieArray = cookieString.split('; ');
      for (const cookie of cookieArray) {
        const [cookieKey, cookieValue] = cookie.split('=');
        if (cookieKey === key) {
          return cookieValue; 
        }
      } return null; // Return null if the key is not found
    },
    ClearData() {
      sessionStorage.clear();
      localStorage.clear();
      this.$store.state.LoggedIn = false;
    }
  },
  created() {
    this.getCookies();
    this.ClearData();
  }
}
</script>

<style scoped>
.login-loader {
  position: absolute !important;
  left: Calc(50% - 25px);
  top: Calc(50% - 25px);
  z-index: 999 !important;
}
</style>