<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-8">
        <h3 class="text-uppercase">Bio Links</h3>
      </div>
      <div class="col-12 col-md-4 d-flex">
        <el-input
          v-model="filter.filterText"
          placeholder="search here..."
          @keyup="getAll()"
          clearable
          class="me-1"
        >
          <template #append>
            <el-button type="primary">
              <i class="bi bi-search"></i>
            </el-button>
          </template>
        </el-input>
        <router-link to="addbiolink">
          <el-button @click="$store.state.pageId = 0" type="primary"
            >Add BioLink</el-button
          >
        </router-link>
      </div>
    </div>
    <el-divider class="mt-1"></el-divider>
    <el-table
      :data="bioLinks"
      :fit="true"
      max-height="80vh"
      class="cstm-table"
      header-cell-class-name="tbl-header"
      size="small"
      stripe
    >
      <template #append>
        <div class="d-flex justify-content-center py-2 px-1">
          <el-pagination
            v-if="bioLinks.length > 0"
            v-model:current-page="pagination.page"
            v-model:page-size="pagination.pageSize"
            :page-sizes="[25, 50, 100]"
            :total="pagination.fetched"
            background
            layout="prev, pager, next, ,sizes"
            small
          />
        </div>
      </template>
      <el-table-column
        label="#"
        type="index"
        width="55"
        align="center"
      ></el-table-column>
      <el-table-column label="Title" prop="title"></el-table-column>
      <el-table-column label="Link" prop="redirectionLink"></el-table-column>
      <el-table-column label="Visits" prop="visits" width="50">
      </el-table-column>
      <el-table-column align="center" label="Action" width="120px">
        <template #default="scope">
          <el-space>
            <el-tooltip content="Copy Link">
              <el-icon @click="takeData(scope.row, 'copy')">
                <i class="mgc_link_2_line text-black action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Edit">
              <el-icon @click="takeData(scope.row, 'edit')">
                <i class="mgc_edit_line text-secondary action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Download">
              <el-icon @click="takeData(scope.row, 'qrcode')">
                <i class="mgc_qrcode_2_line text-primary action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
            <el-tooltip content="Delete">
              <el-icon color="red" @click="takeData(scope.row, 'del')">
                <i class="mgc_delete_2_line action-icon fs-6"></i>
              </el-icon>
            </el-tooltip>
          </el-space>
        </template>
      </el-table-column>
    </el-table>

    <!--    Dialogs-->

    <el-dialog v-model="deleteDialog" title="Delete Biolink" width="450">
      <template #default>
        <span
          >Are you sure? You want to delete
          <span class="mfw-bold text-danger">{{ bioLink.title }}</span>
          Biolink.</span
        >
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="deleteDialog = false">Cancel</el-button>
          <el-button type="danger" @click="(deleteDialog = false), removeLink()"
            >Delete</el-button
          >
        </div>
      </template>
    </el-dialog>
    <el-dialog
      v-model="showQrCode"
      width="300"
      title="Download Qr Code"
      draggable
    >
      <template #default>
        <el-select
          v-model="designId"
          @change="(updateQrcode = new Date()), changeDesign()"
          class="mb-1"
          placeholder="Select QrCode Design"
        >
          <el-option
            v-for="item in designs"
            :key="item"
            :label="item.title"
            :value="item.id"
          >
            <!-- Customize the displayed text -->
            <span>{{ item.title }}</span>
          </el-option>
        </el-select>
        <div class="text-center">
          <div v-if="design.id < 1">
            <img
              :src="bioLink.imageLink"
              width="170px"
              height="170px"
              alt="QrCode"
            />
            <!--            <img v-else :src="'data:image/jpeg;base64,' + bioLink.qrCode" width="170px" height="170px" alt="QrCode" />-->
          </div>
          <QRCodeVue3
            v-else
            ref="qrcode"
            :width="165"
            :height="165"
            :value="bioLink.redirectionLink"
            :key="updateQrcode"
            :qrOptions="{
              typeNumber: 0,
              mode: 'Byte',
              errorCorrectionLevel: 'H',
            }"
            :imageOptions="{
              hideBackgroundDots: true,
              imageSize: 0.4,
              margin: 0,
            }"
            :dotsOptions="design.dotsOptions"
            v-bind:image="design.image"
            :backgroundOptions="{ color: '#ffffff' }"
            :cornersSquareOptions="design.corner.cornersSquareOptions"
            :cornersDotOptions="design.corner.cornersDotOptions"
            fileExt="png"
            myclass="my-qur"
            imgclass="img-qr"
            downloadButton="btn btn-primary"
            :downloadOptions="{ name: 'vqr', extension: 'webp' }"
          />
        </div>
      </template>
      <template #footer>
        <div class="container-fluid px-0 d-flex justify-content-end">
          <el-button text bg @click="showQrCode = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="(showQrCode = false), downloadCode()"
            >Download</el-button
          >
        </div>
      </template>
    </el-dialog>

    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>
<script>
import qrCodeService from "@/Services/linkService";
import linkService from "@/Services/linkService";
import { ShowMessage } from "@/Utility/Utility";
import QRCodeVue3 from "qrcode-vue3";
import designService from "@/Services/designService";

export default {
  name: "BioLink",
  components: { QRCodeVue3 },
  data() {
    return {
      loading: false,
      deleteDialog: false,
      showQrCode: false,
      bioLinks: [],
      updateQrcode: new Date(),
      bioLink: {},
      pagination: {},
      designId: "",
      designs: [],
      design: {
        id: 0,
        title: "",
        image: "",
        userId: sessionStorage.getItem("userId"),
        cornerGradient: false,
        primaryGradient: false,
        corner: {
          cornersSquareOptions: {
            type: "extra-rounded",
            gradient: {
              type: "linear",
              rotation: 0,
              colorStops: [
                { offset: 0, color: "#000" },
                { offset: 1, color: "#000" },
              ],
            },
          },
          cornersDotOptions: {
            type: "extra-rounded",
            gradient: {
              type: "linear",
              rotation: 0,
              colorStops: [
                { offset: 0, color: "#000" },
                { offset: 1, color: "#000" },
              ],
            },
          },
        },
        dotsOptions: {
          type: "dot",
          color: "#26249a",
          gradient: {
            type: "linear",
            rotation: 0,
            colorStops: [
              { offset: 0, color: "#000" },
              { offset: 1, color: "#000" },
            ],
          },
        },
      },
      filter: {
        pageSize: 10,
        page: 1,
        filterText: "",
        isActive: true,
        userId: sessionStorage.getItem("userId"),
        linkType: 40,
      },
    };
  },
  methods: {
    isBase64Image(imageUrl) {
      if (imageUrl === "" || imageUrl === null || imageUrl === undefined) {
        return;
      }
      // Check if the image URL starts with the specified base64 prefix
      return (
        imageUrl.startsWith("data:image/jpeg;base64,") ||
        imageUrl.startsWith("data:image/png;base64,")
      );
    },
    takeData(link, type) {
      this.bioLink = JSON.parse(JSON.stringify(link));
      if (type === "del") {
        this.deleteDialog = true;
      }
      if (type === "copy") {
        navigator.clipboard.writeText(this.bioLink.redirectionLink);
        ShowMessage("success", "Link copied");
      }
      if (type === "edit") {
        this.$store.state.pageId = this.bioLink.linkId;
        this.$router.push("addbiolink");
      }
      if (type === "qrcode") {
        this.designId = "";
        this.resetDesign();
        this.updateQrcode = new Date();
        this.showQrCode = true;
      }
    },
    resetDesign() {
      this.design = {
        id: 0,
        title: "",
        image: "",
        userId: sessionStorage.getItem("userId"),
        cornerGradient: false,
        primaryGradient: false,
        corner: {
          cornersSquareOptions: {
            type: "extra-rounded",
            gradient: {
              type: "linear",
              rotation: 0,
              colorStops: [
                { offset: 0, color: "#000" },
                { offset: 1, color: "#000" },
              ],
            },
          },
          cornersDotOptions: {
            type: "extra-rounded",
            gradient: {
              type: "linear",
              rotation: 0,
              colorStops: [
                { offset: 0, color: "#000" },
                { offset: 1, color: "#000" },
              ],
            },
          },
        },
        dotsOptions: {
          type: "dot",
          color: "#26249a",
          gradient: {
            type: "linear",
            rotation: 0,
            colorStops: [
              { offset: 0, color: "#000" },
              { offset: 1, color: "#000" },
            ],
          },
        },
      };
    },
    downloadCode() {
      this.loading = true;
      if (this.design.id < 1 && this.bioLink.imageLink) {
        // Download directly from the image link if design.id < 1 and imageLink is present
        const a = document.createElement("a");
        a.href = this.bioLink.imageLink;
        a.download = `${this.bioLink.title} Qr Code.png`; // Set file name
        document.body.appendChild(a); // Append to body to make it work in Firefox
        a.click();
        document.body.removeChild(a); // Clean up by removing the element
      } else {
        this.generateByteArray();
      }
      this.loading = false;
    },
    async generateByteArray() {
      try {
        await this.$nextTick();
        // Fetch the image source after waiting for the asynchronous operations
        await new Promise((resolve) => setTimeout(resolve, 0)); // This micro-task ensures the next tick
        const imgSrc = document.querySelector(".img-qr").getAttribute("src");
        this.downloadImage(imgSrc);
      } catch (error) {
        console.error("Error generating Base64 string:", error);
      }
    },
    downloadImage(imgSrc) {
      let a = document.createElement("a"); //Create <a>
      if (!this.isBase64Image(imgSrc)) {
        a.href = "data:image/png;base64," + imgSrc;
      } else {
        a.href = imgSrc;
      } //Image Base64 Goes here
      a.download = this.bioLink.title + " " + "Qr Code.png"; //File name Here
      a.click(); //Downloaded file
    },
    async removeLink() {
      try {
        this.loading = true;
        let res = await linkService.remove(this.bioLink.linkId);
        
        if (res.data.isSuccessful) {
          ShowMessage("success", "Biolink deleted successfully!");
        }
        await this.getAll();
      } catch {
        ShowMessage("error", "something went wrong!");
      }
    },
    async getAll() {
      try {
        this.loading = true;
        let response = await qrCodeService.getAll(this.filter);
        this.bioLinks = response.data.data;
        this.pagination = response.data.pagination;
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    async getAllDesigns() {
      try {
        let response = await designService.all(this.filter);
        this.designs = response?.data?.data;
      } catch (e) {
        console.log(e);
      }
    },
    changeDesign() {
      const selectedDesign = this.designs.find(
        (design) => Number(design.id) === Number(this.designId)
      );
      this.design = selectedDesign;
      if (selectedDesign) {
        this.design = selectedDesign;
      } else {
        console.log(`Design with id ${this.designId} not found.`);
      }
    },
  },
  created() {
    this.getAll();
    this.getAllDesigns();
  },
};
</script>

<style scoped></style>
