<template>
  <div class="container-fluid header-shadow custom-header">
    <div class="row w-100 px-0">
      <div class="col-12 d-flex justify-content-between align-items-center" style="padding-right: 0">
        <div class="d-flex align-items-center">
          <img
              style="width: 100px;"
              src="../../assets/img/logo.png" alt="Logo"/>
          <el-button circle class="btn-s-secondary ms-2"
                     @click="$store.state.sidebarCollapsed = !$store.state.sidebarCollapsed">
            <template #icon>
              <span class="mgc_menu_fill"></span>
            </template>
          </el-button>
<!--          <button class="btn btn-sm btn-outline-success ms-2 border-2" @click="gotoAdtomic">Adtomic</button>-->
        </div>
        <div>
          <div class="d-flex align-items-center">
            <span v-if="User !== {}" class="me-2">{{ User.fullName }}</span>
            <el-avatar
                :size="30"
                class="me-1 btn-s-secondary"
                style="background-color: #e3e3e3"

            >
              <el-icon size="large">
                <UserFilled/>
              </el-icon>
            </el-avatar>
            <el-dropdown placement="bottom-start" trigger="click">
              <el-icon>
                <ArrowDown/>
              </el-icon>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="userDialog = !userDialog">
                    <div class="d-flex align-items-center">
                      <el-icon>
                        <i class="mgc_user_1_line me-3"></i>
                      </el-icon>
                      <span>Profile</span>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item @click="subscriptionDialog = !subscriptionDialog">
                    <div class="d-flex align-items-center">
                      <el-icon>
                        <i class="mgc_key_1_line me-3"></i>
                      </el-icon>
                      <span>Subscription</span>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div class="d-flex align-items-center">
                      <el-icon>
                        <i class="mgc_exit_door_line me-3"></i>
                      </el-icon>
                      <span @click="Logout">Logout</span>
                    </div>
                  </el-dropdown-item>

                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>

    <el-dialog v-model="subscriptionDialog" title="Validate Subscription" class="subscription-dialog" width="500">
      <el-form :model="User" label-position="top">
        <el-form-item>
          <template #label>
            <span class="required">Subscription Code</span>
          </template>
          <el-input v-model="User.subscriptionCode" show-password></el-input>
        </el-form-item>
        <div class="d-flex justify-content-end">
          <el-button type="primary" :disabled="!User.subscriptionCode"
                     @click="ValidateSubscription(User.subscriptionCode)">Validate
          </el-button>
        </div>
        <span v-if="User != null">{{ message }}{{ getFormattedDate(User.expiryDate) }}</span>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button type="primary" @click="subscriptionDialog = false">OK</el-button>
        </div>
      </template>
      <div v-if="subLoading" class="spinner-border sub-loader" style="z-index: 9999 !important;"></div>
    </el-dialog>


    <el-dialog v-model="userDialog" width="400" class="userDialog">
      <template #header>
        <span>User</span>
        <el-divider class="my-1"></el-divider>
      </template>
      <el-form class="pt-0">
        <el-form-item>
          <label>Username</label>
          <el-input v-if="User !== {}" v-model="User.username" disabled></el-input>
        </el-form-item>
        <el-form-item>
          <label>FullName</label>
          <el-input v-if="User !== {}" v-model="User.fullName"></el-input>
        </el-form-item>
        <el-form-item>
          <label>Password</label>
          <el-input v-if="User !== {}" v-model="User.password"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="container-fluid px-0">
          <div class="row">
            <div class="d-flex justify-content-end">
              <el-button text bg class="me-2" @click="userDialog = false">Cancel</el-button>
              <el-button type="primary" @click="updateUser(), userDialog = false">Update</el-button>
            </div>
          </div>
        </div>
      </template>
    </el-dialog>


    <div v-if="loading" class="spinner-border d-loader" style="z-index: 9999 !important;"></div>
  </div>
</template>

<script>
import {ArrowDown, UserFilled} from "@element-plus/icons-vue";

import axios from "axios";
import authenticationService from "@/Services/userService";
import {ShowMessage} from "@/Utility/Utility";
import userService from "@/Services/userService";
import moment from "moment";

export default {
  name: "HeaderComp",
  components: {UserFilled, ArrowDown},
  data() {
    return {
      loading: false,
      subLoading: false,
      userDialog: false,
      subscriptionDialog: false,
      dialog: false,
      drawer: true,
      showSidebar: false,
      message: "Your subscription is valid up to ",
      User: {},
    }
  },
  methods: {
    gotoAdtomic() {
      // eslint-disable-next-line no-unused-vars
      //var newTab = window.open('http://localhost:8081/?token=' + sessionStorage.getItem("token"));
      // eslint-disable-next-line no-unused-vars
      var newTab = window.open('http://adtomic.magiclinkz.com/?token=' +sessionStorage.getItem("token"));
    },
    getFormattedDate(date) {
      return moment(date).format("DD, MMM YYYY");
    },
    closeApp() {
      window.close();
    },
    async getUser() {
      try {
        let response = await authenticationService.getUser();
        this.User = response.data;
        this.CheckSubscription();
      } catch (e) {
        console.log(e)
      }
    },
    CheckSubscription: function () {
      
      if (this.User.role != 10) {
        if (this.User.expiryDate == null) {
          this.$store.state.isLocked = true;
          return;
        }
        const dbDate = new Date(this.User.expiryDate);
        const currentDate = new Date();
        this.$store.state.isLocked = dbDate < currentDate;
        if (!this.$store.state.isLocked) {
          this.message = "Your subscription has been extended to ";
        }
      }
    },
    async ValidateSubscription(subscriptionCode) {
      try {
        this.subLoading = true;
        let model = {
          subscriptionCode: subscriptionCode,
          username: this.User.username,
        };
        let response = await userService.validateSubscription(model);
        if (response.data.isSuccessful) {
          ShowMessage("success", response.data.msg)
          this.subLoading = false;
          this.getUser();
          this.subscriptionDialog = false;
        } else {
          this.subLoading = false;
          ShowMessage("error", response.data.msg)
        }
      } catch {
        ShowMessage("error", "Something went wrong!");
        this.loading = false;
      }
    },
    async updateUser() {
      try {
        this.loading = true;
        let response = await userService.updateProfile(this.User);
        if (response.data.data) {
          ShowMessage("success", "Profile updated successfully");
          this.getUser();
          this.loading = false;
        } else {
          this.loading = false;
          ShowMessage("error", response.data.msg);
        }
      } catch (err) {
        this.loading = false;
        ShowMessage("error", "Something went wrong!.")
      }
    },

    Logout() {
      this.$store.state.isLocked = false;
      sessionStorage.clear();
      localStorage.clear();
      this.$router.push("/");
    }
  },
  created() {
    axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("token");
    this.getUser();
  }
}
</script>

<style scoped>
.subscription-dialog{
  z-index: 999999999 !important;
}
.sub-loader{
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
}
</style>